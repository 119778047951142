import Vue from "vue";
import Vuex from "vuex";
import createMultiTabState from "vuex-multi-tab-state";
import socket from "../socket";
import { UserService } from "@/services/user.service";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: undefined as unknown as any,
    accessToken: "",
    refreshToken: "",
    isMobile:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      ),
    ping: 0,
    ip: "0.0.0.0",
    serverName: "",
    scrollTop: 0,
    developer: false,
    tabActive: true,
    unAuthPath: "",
  },
  getters: {
    localUser: () => {
      let user = localStorage.getItem("user");
      // console.log(user, "<= user");
      if (!user) user = sessionStorage.getItem("user");
      // console.log(user, "<= user");
      return JSON.parse(user as string);
    },

    accessToken: (state, getters) => {
      if (state.accessToken) return state.accessToken;
      getters.localUser?.accessToken;
    },
    unAuthPath: (state) => state.unAuthPath,
  },
  mutations: {
    windowFocus(state) {
      state.tabActive = true;
    },
    windowBlur(state) {
      state.tabActive = false;
    },
    changeDeveloper(state, data) {
      state.developer = data;
    },
    setBlackList(state, data) {
      if (!state.user) return;
      state.user.blacklist = data;
    },
    setUser(state, data) {
      state.user = data;
      state.accessToken = data?.accessToken;
      state.refreshToken = data?.refreshToken;
    },
    setUnAuthPath(state, data) {
      state.unAuthPath = data;
      console.log("setUnAuthPath", data);
    },
    savePersistUser(state, data) {
      state.user = data;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;

      socket.auth = { token: data.accessToken };
      socket.open();

      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem(
        "tempUser",
        JSON.stringify({
          username: data.username,
          displayName: data.displayName,
          avatar: data.profile.avatar,
        })
      );
    },
    saveSessionUser(state, data) {
      state.user = data;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;

      socket.auth = { token: data.accessToken };
      socket.open();

      sessionStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem(
        "tempUser",
        JSON.stringify({
          username: data.username,
          displayName: data.displayName,
          avatar: data.profile.avatar,
        })
      );
    },
    logout(state, data) {
      console.log("logout");
      state.user = undefined;
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");

      if (!data?.expired) localStorage.removeItem("tempUser");
      console.log("2");
      router.push({ path: "/" });
      socket.close();
      //router.push({ name: "Sign", params: { force: "1" } });
    },
    updateAccessToken(state, data) {
      let user = JSON.parse(sessionStorage.getItem("user") as string);
      if (user) {
        user.accessToken = data;
        sessionStorage.setItem("user", JSON.stringify(user));
      }

      user = JSON.parse(localStorage.getItem("user") as string);
      if (user) {
        user.accessToken = data;
        localStorage.setItem("user", JSON.stringify(user));
      }
      socket.auth = { token: data };
      socket.open();

      state.accessToken = data;
    },
    updateRefreshToken(state, data) {
      let user = JSON.parse(sessionStorage.getItem("user") as string);
      if (user) {
        user.refreshToken = data;
        sessionStorage.setItem("user", JSON.stringify(data));
      }

      user = JSON.parse(localStorage.getItem("user") as string);
      if (user) {
        user.refreshToken = data;
        localStorage.setItem("user", JSON.stringify(data));
      }

      state.refreshToken = data;
    },
    editUser(state, data) {
      const setArr = data.setting.split(".");

      console.log(
        data.setting.split(".")[0],
        (state.user as any)[`${setArr[0]}`],
        data.setting.split(".")[1],
        data.value,
        "<- EditUser"
      );

      if (data.setting.includes(".")) {
        if (!(state.user as any)[`${setArr[0]}`])
          (state.user as any)[`${setArr[0]}`] = {};
        Vue.set(state.user[`${setArr[0]}`], setArr[1], data.value);
      } else {
        Vue.set(state.user as object, data.setting, data.value);
      }
      state.user.__v = state.user.__v + 1;
      // state.user = data.value
      if (localStorage.getItem("user"))
        return localStorage.setItem("user", JSON.stringify(state.user));
      if (sessionStorage.getItem("user"))
        return sessionStorage.setItem("user", JSON.stringify(state.user));
    },
    updateUser(state, data) {
      const userData = {
        ...data,
        accessToken: state.user.accessToken,
        refreshToken: state.user.refreshToken,
      };
      Vue.set(state as object, "user", userData);

      // state.user = data.value
      if (localStorage.getItem("user"))
        return localStorage.setItem("user", JSON.stringify(state.user));
      if (sessionStorage.getItem("user"))
        return sessionStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  actions: {
    loadUser({ commit, getters, dispatch }) {
      const user = getters.localUser;
      dispatch("updateBlacklist");

      commit("setUser", user);
    },
    async updateBlacklist({ commit, state }) {
      if (!state.user) return commit("setBlackList", []);
      const blacklist = await UserService.getBlacklist();
      // const blacklist = [];
      if (!blacklist) return commit("setBlackList", []);
      commit("setBlackList", blacklist.data);
    },
  },
  modules: {},
  plugins: [createMultiTabState()],
});
