
import { defineComponent } from "vue";
type StepsType = { text: string; to?: string; done?: boolean }[];
const discordLink = process.env.VUE_APP_DISCORD_SETTINGS;
export default defineComponent({
  name: "CompleteReg",
  props: {
    data: Object,
  },
  data() {
    return {
      steps: [
        { text: "Привяжи Discord", to: discordLink },
        { text: "Добавь аватарку", to: "/settings?action=setAvatar&p=profile" },
        { text: "Добавь обложку", to: "/settings?action=setBanner&p=profile" },
        { text: "Создай первое лобби", to: "/lobby/create" },
        // { text: "Подтверди почту (NYI)", to: "/settings?p=main" },
        // { text: "Отыграть 30 минут", to: "/lobby" },
      ] as StepsType,
    };
  },
  methods: {
    redir(to: string) {
      if (!to) return;
      if (to.startsWith("http")) return window.open(to);
      this.$router.push(to);
    },
    getSteps() {
      const user = this.$store.state.user;
      if (!user) return;
      if (user?.integrations?.discord) this.$set(this.steps[0], "done", true);
      if (!user.profile.avatar.includes("game/file/"))
        this.$set(this.steps[1], "done", true);
      if (!user.profile.banner.includes("game/file/"))
        this.$set(this.steps[2], "done", true);

      if (user.stats.createdLobbyCount > 0) this.steps[3].done = true;
      // if (user.profile.banner) this.steps[2].done = true;
    },
  },
  sockets: {
    updateUser() {
      const vm = this as any;
      setTimeout(() => {
        vm.getSteps();
      }, 100);
    },
    disconnect() {
      const vm = this as any;
      setTimeout(() => {
        vm.getSteps();
      }, 100);
    },
  },
  mounted() {
    this.getSteps();
  },
  computed: {
    user() {
      const u = (this as any).$store.state.user;
      return u;
    },
  },
});
