
import "@/components";
import { defineComponent } from "vue";
import "@/themes";
import { ThemeController, ThemesList } from "@/themes";
import { UserService } from "./services/user.service";
const invoke = (window as unknown as { __TAURI__: any }).__TAURI__?.invoke;
import VConsole from "vconsole";
import CompleteReg from "@/components/CompleteReg/CompleteReg.vue";
import { AuthService } from "./services/auth.service";
import { LoginUserDto, RegUserDto } from "@easygaming/api";

setTimeout(() => {
  if (invoke) invoke("close_splashscreen");
}, 5000);

// import io from "socket.io-client";
// import VueSocketIOExt from "vue-socket.io-extended";
// import Vue from "vue";
// register Swiper custom elements

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

export type DataType = {
  theme: string;
  // socketOk: boolean;
  mobileMenuOpen: boolean;
  lastLobbyId: string | undefined;
  vConsole: any;
  pwaWorker: any;
  refreshing: boolean;
  registration: any;
  updateExists: boolean;
  cookieSnackbar: boolean;
  showRegDialog: boolean;
  user: {
    username?: string;
    email?: string;
    password?: string;
    code?: string;
  };
  loginLoading: boolean;
  state: string;
  agreed: boolean;
};

export default defineComponent({
  name: "app",
  components: { CompleteReg },
  data(): DataType {
    return {
      theme: "dark",
      // socketOk: true,
      lastLobbyId: undefined,
      mobileMenuOpen: false,
      vConsole: undefined,
      pwaWorker: undefined,
      refreshing: false,
      registration: null,
      updateExists: false,
      cookieSnackbar: false,
      showRegDialog: false,
      user: { code: "33" },
      state: "reg",
      agreed: false,
      loginLoading: false,
    };
  },
  sockets: {
    connect() {
      this.$socket.client.emit("chat", "To sockets");
      UserService.getUser(this.$store.state.user._id);
    },
    disconnect() {
      UserService.getUser(this.$store.state.user._id);
    },
    chat() {
      //
    },
    newLogin(): void {
      this.$notify({
        data: {
          type: "warning",
          title: "Новый вход в аккаунт",
          text: "Кто-то вошёл в ваш аккаунт",
          icon: "account-check",
        },
      });
    },
    async updateUser(): Promise<void> {
      const userRes = (await UserService.getUser(
        this.$store.state.user._id
      )) as any;
      this.$store.commit("updateUser", userRes.data);
    },
    updateBlacklist(): void {
      this.$store.dispatch("updateBlacklist");
    },
  },
  methods: {
    // reloadPWA() {
    //   //console.log(this.pwaWorker);
    //   //console.log(this.pwaWorker.detail);
    //   this.pwaWorker.waiting?.postMessage({ type: "SKIP_WAITING" });
    //   //this.pwaWorker.detail.postMessage({ action: "skipWaiting" });
    //   console.log("reloading PWA...");
    // },
    acceptCookie() {
      this.cookieSnackbar = false;
      localStorage.setItem("cookieOk", "1");
    },
    tryConnectSocket() {
      // console.log("tryConnectSocket");
      if (!this.$store.state.user || this.$socket?.connected) return;
      this.$socket.client.auth = { token: this.$store.state.user.accessToken };
      this.$socket.client.open();
      // const vm = this;
      // setTimeout(function () {
      //   vm.tryConnectSocket();
      // }, 2000);
    },
    updateAvailable(event) {
      const vm = this;
      this.registration = event.detail;
      this.updateExists = true;
      this.$notify({
        duration: -1,
        data: {
          title: "Доступно обновление",
          text: "Обновление сайта загружено и готово к установке. После установки все вкладки сайта будут перезагружены.",
          type: "secondary",
          buttonA: "Обновить",
          actionA: () => vm.refreshApp(),
        },
      });
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    createConsole() {
      const vConsole = new VConsole({ theme: "dark" });

      this.vConsole = vConsole;
    },
    destroyConsole() {
      this.vConsole.destroy();
    },
    onSlideChange() {
      //
    },
    log() {
      //
    },
    anchorHashCheck() {
      // console.log(window.location.href, this.$route.path);
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1));
        if (el) {
          window.scrollTo(0, el.offsetTop);
        }
      }
    },
    logout() {
      this.$store.commit("logout");
    },
    login() {
      if (!this.user.username) {
        return this.$notify({
          data: {
            type: "error",
            title: "Почта/Логин не указано",
            icon: "close-octagon",
          },
        });
      }

      if (!this.user.password) {
        return this.$notify({
          data: {
            type: "error",
            title: "Пароль не указан",
            icon: "close-octagon",
          },
        });
      }

      this.loginLoading = true;

      AuthService.login(this.user as LoginUserDto)
        .then((res: any) => {
          this.loginLoading = false;

          console.log(res, " login res");
          let user = res.data;

          this.$store.commit("savePersistUser", user);

          // if (!this.from) return this.$router.replace("/");
          // this.$router.replace(this.from);
          this.regDialog = false;
          if (this.unAuthPath) this.$router.push(this.unAuthPath);
          this.$store.commit("setUnAuthPath", "");

          // if (!user.integrations?.discord) this.noDsDialog = true;
          // else (this as any).createLobby();
        })
        .catch((err: any) => {
          console.log(err, "ererere2");
          this.loginLoading = false;

          this.$notify({
            data: {
              type: "error",
              title: "Ошибка авторизации",
              text: err.response.data.message,
              icon: "close-octagon",
            },
          });
        });
    },
    register() {
      if (!this.agreed)
        return this.$notify({
          data: {
            type: "error",
            title: "Подтвердите согласие с политикой конфиденциальности",
            icon: "sticker-check-outline",
          },
        });

      if (!this.user.username) {
        return this.$notify({
          data: {
            type: "error",
            title: "Логин не указан",
            icon: "close-octagon",
          },
        });
      }

      if (!this.user.email) {
        return this.$notify({
          data: {
            type: "error",
            title: "Почта не указана",
            icon: "close-octagon",
          },
        });
      }

      if (!this.user.password) {
        return this.$notify({
          data: {
            type: "error",
            title: "Пароль не указан",
            icon: "close-octagon",
          },
        });
      }
      this.loginLoading = true;

      AuthService.register(this.user as RegUserDto)
        .then(() => {
          this.loginLoading = false;
          this.login();
        })
        .catch((err: any) => {
          this.loginLoading = false;
          this.$notify({
            data: {
              type: "error",
              title: "Ошибка авторизации",
              text: err.response.data.message,
              icon: "close-octagon",
            },
          });
        });
    },
    cycleThemes() {
      if (this.theme == "light") {
        ThemeController.loadDefaultTheme(ThemesList.Dark);
        this.theme = "dark";
        localStorage.setItem("theme", this.theme);
      } else {
        ThemeController.loadDefaultTheme(ThemesList.Light);
        this.theme = "light";
        localStorage.setItem("theme", this.theme);
      }
    },
  },
  updated() {
    // window.scrollTo(0, 0);
    this.anchorHashCheck();

    this.lastLobbyId = localStorage.getItem("lastlobby") || undefined;
  },
  mounted() {
    console.log("APP MOUNTED");

    const vm = this as any;

    this.$store.state.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );

    navigator.serviceWorker?.addEventListener("controllerchange", () =>
      window.location.reload()
    );

    vm.$root.tabActive = true;
    document.addEventListener("visibilitychange", function () {
      vm.$root.tabActive = !document.hidden;
    });

    if (this.$store.state.user) {
      UserService.getUser(this.$store.state.user._id);
      // this.$socket.client.auth = { token: this.$store.state.user.accessToken };
      // this.$socket.client.open();
      this.tryConnectSocket();
    }
    const rootElement = document.documentElement;
    if (!this.$store.state.isMobile) {
      rootElement.style.minWidth = "1240px";
      rootElement.style.overflow = "auto scroll";
    }

    // user leaves the tab
    window.addEventListener("blur", () => {
      this.$store.commit("windowBlur");
    });
    // user enters the tab (again)
    window.addEventListener("focus", () => {
      this.$store.commit("windowFocus");
    });
    document.addEventListener(
      "contextmenu",
      function (e) {
        // console.log(e, "You've tried to open context menu"); //here you draw your own menu
        if (!e.shiftKey) e.preventDefault();
      },
      false
    );

    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes

    // var hidden, visibilityState, visibilityChange;

    // if (typeof document.hidden !== "undefined") {
    //   (hidden = "hidden"),
    //     (visibilityChange = "visibilitychange"),
    //     (visibilityState = "visibilityState");
    // }

    var document_hidden = document["hidden"];

    document.addEventListener("visibilitychange", function () {
      if (document_hidden != document["hidden"]) {
        if (document["hidden"]) {
          // Document hidden
        } else {
          // Document shown
          vm.tryConnectSocket();
        }

        document_hidden = document["hidden"];
      }
    });

    //  min-width: 1240px;
    // overflow-x: scroll

    // const socket = io(
    //   `${process.env.VUE_APP_API_URL}`
    //     .replace("http://", "ws://")
    //     .replace("https://", "wss://"),
    //   {
    //     transports: ["websocket"],
    //     auth: {
    //       token: this.currentUser ? this.currentUser.accessToken : undefined,
    //     },
    //   }
    // );
    // const socket = io("http://192.168.0.20:3000");
    // Vue.use(VueSocketIOExt, socket);

    // setTimeout(() => {
    //   document.getElementsByTagName("html")[0].classList.toggle("hidey");
    //   window.scrollTo(0, 0);

    //   document.getElementById("preloader")!.classList.add("hidePreloader");
    //   setTimeout(() => {
    //     document.getElementById("preloader")!.style.display = "none";
    //   }, 500);
    // }, 1000);
  },
  async created() {
    console.log("APP CREATED");
    this.$store.commit("setUnAuthPath", "");

    this.$store.dispatch("loadUser");
    this.$moment.locale("ru");

    if (!localStorage.getItem("theme")) {
      ThemeController.loadDefaultTheme(ThemesList.Dark);
      localStorage.setItem("theme", this.theme);
    }

    this.theme = localStorage.getItem("theme") || "dark";

    if (!localStorage.getItem("cookieOk")) this.cookieSnackbar = true;

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
    // const ipData = await axios
    //   .get("https://api.ipify.org?format=json")
    //   .catch((e) => e);
    // if (!axios.isAxiosError(ipData)) this.$store.state.ip = ipData.data.ip;
    // else console.error(ipData);
  },
  computed: {
    socketOk() {
      // if (!this.$socket?.connected) this.tryConnectSocket();
      return this.$socket?.connected;
    },
    discordLink() {
      return process.env.VUE_APP_DISCORD_SETTINGS;
    },
    regDialog() {
      const un = this.unAuthPath;
      console.log(un, ".unAuthPath");
      if (this.$route.path == "/sign") return false;
      if (!un) return false;
      return true;
    },
    unAuthPath() {
      return this.$store.getters.unAuthPath;
    },
  },
});
