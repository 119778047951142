var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.steps.filter((v) => v.done).length < _vm.steps.length && _vm.$store.state.user
  )?_c('div',{staticClass:"profile-plate b px-4",staticStyle:{"background":"var(--backgrounds-upper)"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('p',{staticClass:"l bold"},[_vm._v("Заполни профиль полностью")]),_c('span',{staticClass:"profile-filling-steps"},[_vm._v(_vm._s(_vm.steps.filter((v) => v.done).length)+"/"+_vm._s(_vm.steps.length))])]),_c('div',{staticClass:"profile-filling-progress",staticStyle:{"margin-top":"8px"}},[_c('div',{style:({
        width: `${Math.round(
          (_vm.steps.filter((v) => v.done).length / _vm.steps.length) * 100
        )}%`,
      })})]),_c('div',{staticClass:"steps",staticStyle:{"margin-top":"8px"}},_vm._l((_vm.steps.filter((v) => !v.done)),function(step,i){return _c('a',{key:i,staticClass:"step",on:{"click":function($event){return _vm.redir(step.to)}}},[_c('div',{staticClass:"dot"}),_c('p',{staticClass:"m"},[_vm._v(_vm._s(step.text))])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }